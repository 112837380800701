<route>
{
  "meta": {
    "permission": [
      "sequnces.add_sequence", "sequnces.change_sequence", "sequnces.delete_sequence", "sequnces.view_sequence"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar :title="$tc('sequence', 1)" :loading="load"></i-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="model"
                  :name="$t('model')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="model"
                    disabled
                    outlined
                    :error-messages="errors[0]"
                    v-model="sequence.model"
                    color="secondary"
                    :label="$tc('model', 1)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="number_increment"
                  :name="$t('number_increment')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="number_increment"
                    outlined
                    :error-messages="errors[0]"
                    v-model="sequence.number_increment"
                    color="secondary"
                    :label="$tc('number_increment', 1)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="prefix"
                  :name="$t('prefix')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="prefix"
                    outlined
                    :error-messages="errors[0]"
                    v-model="sequence.prefix"
                    color="secondary"
                    :label="$tc('prefix', 1)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="padding"
                  :name="$t('padding')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="padding"
                    outlined
                    :error-messages="errors[0]"
                    v-model="sequence.padding"
                    color="secondary"
                    :label="$tc('padding', 1)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="next_number"
                  :name="$t('next_number')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="next_number"
                    outlined
                    :error-messages="errors[0]"
                    v-model="sequence.next_number"
                    color="secondary"
                    :label="$tc('next_number', 1)"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  vid="suffix"
                  :name="$t('suffix')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    name="suffix"
                    outlined
                    :error-messages="errors[0]"
                    v-model="sequence.suffix"
                    color="secondary"
                    :label="$tc('suffix', 1)"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
    <v-alert
      type="info"
      outlined
      :value="true"
      class="font-weight-black title my-5 roundCard"
    >
      {{ `${$t('example')}: ${calculatedSequence}` }}
    </v-alert>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      load: false,
      sequence: {
        pk: '',
        prefix: '',
        suffix: '',
        padding: '',
        number_increment: '',
        next_number: '',
        model: ''
      }
    }
  },
  computed: {
    calculatedSequence() {
      let next = '' + this.sequence.next_number
      let zero = '0'
        .repeat(this.sequence.padding)
        .substring(0, this.sequence.padding - next.length)
      return this.sequence.prefix + zero + next + this.sequence.suffix
    }
  },
  watch: {
    'sequence.padding': {
      handler(val) {
        this.sequence.padding = val < 0 ? val * -1 : val
      }
    }
  },
  methods: {
    submit() {
      this.load = true
      this.$api.sequence
        .edit({
          pk: this.sequence.pk,
          form: this.sequence
        })
        .then(() => {
          this.$toast.success(
            `${this.$tc('sequence', 1)} ${this.$tc('edited', 2)}`
          )
          this.$router.push({ name: 'sequence' })
        })
        .finally(() => (this.load = false))
    },
    getSequence() {
      this.$api.sequence
        .show({
          pk: this.codification({ mode: 'decode', code: this.$route.params.pk })
        })
        .then((res) => {
          this.sequence = res.data
          this.$route.meta.title = this.sequence.model
        })
        .catch(() => this.$router.push({ name: 'sequence' }))
    }
  },
  mounted() {
    this.getSequence()
  }
}
</script>
